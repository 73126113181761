<template>
    <div>
        <NavbarComponent />
        <div class="custom-container">

            <div class="container mx-auto mt-20 p-6 bg-[#100f0d] rounded-lg shadow-lg">
                <h1 class="text-white">PROFILE MANAGEMENT</h1>
                <div class="flex justify-start mb-6 space-x-4 border-b border-[#2b1b0d]">
                    <div :class="[
                        'px-6 py-2 cursor-pointer transition-colors duration-300 transform hover:scale-105',
                        activeTab === 'profile' ? 'text-[#c5992a] border-b-2 border-[#c5992a]' : 'text-[#f5f3f5] hover:text-[#ead4a2]'
                    ]" @click="activeTab = 'profile'">
                        Account
                    </div>
                    <div :class="[
                        'px-6 py-2 cursor-pointer transition-colors duration-300 transform hover:scale-105',
                        activeTab === 'subscriptions' ? 'text-[#c5992a] border-b-2 border-[#c5992a]' : 'text-[#f5f3f5] hover:text-[#ead4a2]'
                    ]" @click="activeTab = 'subscriptions'">
                        Subscription
                    </div>
                    <div :class="[
                        'px-6 py-2 cursor-pointer transition-colors duration-300 transform hover:scale-105',
                        activeTab === 'password' ? 'text-[#c5992a] border-b-2 border-[#c5992a]' : 'text-[#f5f3f5] hover:text-[#ead4a2]'
                    ]" @click="activeTab = 'password'">
                        Security
                    </div>
                </div>
                <div class="bg-[#100f0d] p-8 rounded-lg shadow-md text-[#f5f3f5]">
                    <div v-if="activeTab === 'profile'">
                        <h2 class="text-2xl font-semibold mb-6 text-white">Account Details</h2>
                        <div v-if="user">
                            <div
                                :class="['profile-image-card p-6 rounded-lg shadow-md flex flex-col md:flex-row items-center justify-between w-full mb-6', subscriptionBackgroundClass]">
                                <div class="avatar-container mb-2 md:mb-0 flex items-center space-x-4">
                                    <div v-if="user.avatar">
                                        <img :src="storage + user.avatar" alt="User Avatar"
                                            class="w-36 h-36 rounded-full">
                                    </div>
                                    <div v-else>
                                        <img :src="defaultAvatar" alt="User Avatar" class="w-36 h-36 rounded-full">
                                    </div>


                                    <div v-if="user.avatar">
                                        <button @click="showAvatarUploadModal = true"
                                            class="edit-avatar text-green-600 px-4 py-2 rounded mr-2 hover:underline focus:outline-none">
                                            Edit Photo
                                        </button>
                                        <button @click="showAvatarDeleteModal = true"
                                            class="delete-avatar text-red-600 px-4 py-2 rounded hover:underline focus:outline-none">
                                            Remove Photo
                                        </button>
                                    </div>
                                    <div v-else>
                                        <button @click="showAvatarUploadModal = true"
                                            class="upload-avatar bg-amber-400 text-black px-4 py-2 rounded hover:underline focus:outline-none">Upload
                                            Avatar</button>
                                    </div>

                                </div>
                                <div class="membership-title text-black font-bold">
                                    <h1><b>{{ user.subscription }} Member</b></h1>
                                </div>
                            </div>
                        </div>
                        <div class="account-details flex-1">
                            <div v-if="user">
                                <form @submit.prevent="updateProfile">
                                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        <div class="mb-1">
                                            <label for="first-name" class="block mb-2 font-medium text-[#f5f3f5]">First
                                                Name:</label>
                                            <input type="text" id="first-name" v-model="user.first_name"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]"
                                                required />
                                        </div>
                                        <div class="mb-1">
                                            <label for="last-name" class="block mb-2 font-medium text-[#f5f3f5]">Last
                                                Name:</label>
                                            <input type="text" id="last-name" v-model="user.last_name"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]"
                                                required />
                                        </div>
                                        <div class="mb-1">
                                            <label for="email"
                                                class="block mb-2 font-medium text-[#f5f3f5]">Email:</label>
                                            <input type="email" id="email" v-model="user.email"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]"
                                                required />
                                        </div>
                                        <div class="mb-1">
                                            <label for="phone"
                                                class="block mb-2 font-medium text-[#f5f3f5]">Phone:</label>
                                            <input type="text" id="phone" v-model="user.phone"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]" />
                                        </div>
                                        <div class="mb-1">
                                            <label for="address"
                                                class="block mb-2 font-medium text-[#f5f3f5]">Address:</label>
                                            <input type="text" id="address" v-model="user.address"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]" />
                                        </div>
                                        <div class="mb-1">
                                            <label for="state"
                                                class="block mb-2 font-medium text-[#f5f3f5]">State:</label>
                                            <input type="text" id="state" v-model="user.state"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]" />
                                        </div>
                                        <div class="mb-1">
                                            <label for="zip" class="block mb-2 font-medium text-[#f5f3f5]">Zip:</label>
                                            <input type="text" id="zip" v-model="user.zip"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]" />
                                        </div>
                                        <div class="mb-1">
                                            <label for="country"
                                                class="block mb-2 font-medium text-[#f5f3f5]">Country:</label>
                                            <input type="text" id="country" v-model="user.country"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]" />
                                        </div>
                                        <div class="mb-1">
                                            <label for="language"
                                                class="block mb-2 font-medium text-[#f5f3f5]">Language:</label>
                                            <input type="text" id="language" v-model="user.language"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]" />
                                        </div>
                                        <div class="mb-4">
                                            <label for="timezone"
                                                class="block mb-2 font-medium text-[#f5f3f5]">Timezone:</label>
                                            <input type="text" id="timezone" v-model="user.timezone"
                                                class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]" />
                                        </div>
                                    </div>
                                    <button type="submit" :disabled="!isProfileChanged"
                                        class="px-6 py-2 bg-amber-600 text-white rounded-full transition-colors duration-300 hover:bg-[#ead4a2] disabled:opacity-50 disabled:cursor-not-allowed">
                                        Update Profile
                                    </button>
                                </form>
                            </div>
                            <div v-else>
                                <Spinner />
                            </div>
                        </div>
                    </div>

                    <!-- <div v-if="activeTab === 'subscriptions'">
                        <h2 class="text-2xl font-semibold mb-6 text-white">Subscription</h2>
                        <div v-if="user">
                            <div :class="['mb-4 p-3 border rounded-lg', subscriptionBackgroundClass, 'text-black']">
                                <label class="block mb-2 font-medium">Current Membership:</label>
                                <div class="w-full">
                                    {{ user.subscription }}
                                </div>
                            </div>
                            <div v-if="subscription && user.subscription != 'Free'">
                                <div class="mb-4">
                                    <label class="block mb-2 font-medium">Start Date:</label>
                                    <div
                                        class="w-full p-3 border border-[#2b1b0d] rounded-lg bg-[#100f0d] text-[#f5f3f5]">
                                        {{ subscription.start_date }}
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="block mb-2 font-medium">End Date:</label>
                                    <div
                                        class="w-full p-3 border border-[#2b1b0d] rounded-lg bg-[#100f0d] text-[#f5f3f5]">
                                        {{ subscription.end_date }}
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="block mb-2 font-medium">Status:</label>
                                    <div
                                        class="w-full p-3 border border-[#2b1b0d] rounded-lg bg-[#100f0d] text-[#f5f3f5]">
                                        {{ subscription.status }}
                                    </div>
                                </div>
                                <div class="mb-4">
                                    <label class="block mb-2 font-medium">Subscription ID:</label>
                                    <div
                                        class="w-full p-3 border border-[#2b1b0d] rounded-lg bg-[#100f0d] text-[#f5f3f5]">
                                        {{ subscription.stripe_subscription_id }}
                                    </div>
                                </div>
                                <button @click="openCancelModal" :disabled="subscription.status === 'canceled'" :class="[
                                    'px-6 py-2 rounded-full transition-colors duration-300',
                                    subscription.status === 'canceled' ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#c5992a] hover:bg-[#ead4a2] text-[#100f0d]'
                                ]">
                                    Cancel Subscription
                                </button>
                                <a :href="'https://billing.stripe.com/p/login/test_7sI14ofRbd8D2FGdQQ'" target="_blank"
                                    :class="[
                                        'ml-4 px-6 py-2 rounded-full transition-colors duration-300 bg-[#625AFF]  text-white'
                                    ]">
                                    Manage Subscription in Stripe
                                </a>
                            </div>
                        </div>
                        <div v-else>
                            <Spinner />
                        </div>
                    </div> -->

                    <div v-if="activeTab === 'subscriptions'" class="flex justify-center items-center">
                        <div v-if="user"
                            :class="['w-full max-w-4xl p-8 rounded-lg shadow-lg', subscriptionBackgroundClass]">
                            <!-- Credit Card Component -->
                            <div class="flex flex-col justify-between h-full text-black">
                                <div class="flex justify-between items-center mb-6">
                                    <div class="text-4xl font-semibold">Wisdom eBooks Club</div>
                                    <div class="text-lg uppercase flex items-center">
                                        <span :class="[
                                            'inline-block w-3 h-3 rounded-full mr-2',
                                            subscription.status === 'active' ? 'bg-green-500' : '',
                                            subscription.status === 'canceled' ? 'bg-red-500' : ''
                                        ]"></span>
                                        {{ subscription.status }}
                                    </div>
                                </div>
                                <div class="text-6xl font-bold mb-4">{{ user.subscription }} Membership</div>
                                <div v-if="!(user.subscription === 'Free')" class="flex justify-left mb-4">
                                    <div class="mr-6">
                                        <div class="text-sm">Start Date</div>
                                        <div>{{ formattedStartDate }}</div>
                                    </div>
                                    <div>
                                        <div class="text-sm">End Date</div>
                                        <div>{{ formattedEndDate }}</div>
                                    </div>
                                </div>

                                <div class="mb-4">
                                    <div class="text-2xl font-semibold">{{ user.first_name }} {{ user.last_name }}</div>
                                    <div class="text-sm">Member Since</div>
                                    <div>{{ formattedMemberSince }}</div>
                                </div>
                                <div class="flex justify-between mt-auto">
                                    <a :href="'https://billing.stripe.com/p/login/test_7sI14ofRbd8D2FGdQQ'"
                                        target="_blank" class="transition-colors duration-300 text-violet-600"
                                        v-if="user.subscription !== 'Free'">
                                        Manage with Stripe
                                    </a>
                                    <button @click="openCancelModal" :disabled="subscription.status === 'canceled'"
                                        :class="[
                                            'transition-colors duration-300',
                                            subscription.status === 'canceled' ? 'text-gray-400 cursor-not-allowed' : 'text-gray-400'
                                        ]" v-if="user.subscription !== 'Free'">
                                        Cancel Subscription
                                    </button>
                                    <button href="/subscription" target="_blank"
                                        class="transition-colors duration-300 text-black bg-amber-400 hover:underline px-4 py-2 rounded"
                                        v-if="user.subscription === 'Free'">
                                        SUBSCRIBE NOW!
                                    </button>
                                </div>
                            </div>
                            <!-- End of Credit Card Component -->
                        </div>
                        <div v-else>
                            <Spinner />
                        </div>
                    </div>


                    <div v-if="activeTab === 'password'">
                        <h2 class="text-2xl font-semibold mb-6 text-white">Change Password</h2>
                        <form @submit.prevent="updatePassword">
                            <div class="mb-4">
                                <label for="current-password" class="block mb-2 font-medium text-[#f5f3f5]">Current
                                    Password:</label>
                                <input type="password" id="current-password" v-model="currentPassword"
                                    class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]"
                                    required />
                                <p v-if="errors.current_password" class="text-red-500 text-sm mt-1">{{
                                    errors.current_password[0] }}</p>
                            </div>
                            <div class="mb-4">
                                <label for="new-password" class="block mb-2 font-medium text-[#f5f3f5]">New
                                    Password:</label>
                                <input type="password" id="new-password" v-model="newPassword"
                                    class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]"
                                    required />
                                <p v-if="errors.new_password" class="text-red-500 text-sm mt-1">{{
                                    errors.new_password[0] }}</p>
                            </div>
                            <div class="mb-4">
                                <label for="confirm-password" class="block mb-2 font-medium text-[#f5f3f5]">Confirm New
                                    Password:</label>
                                <input type="password" id="confirm-password" v-model="confirmPassword"
                                    class="w-full p-3 border border-[#2b1b0d] rounded-lg focus:outline-none focus:ring-2 focus:ring-[#c5992a] bg-[#100f0d] text-[#f5f3f5]"
                                    required />
                                <p v-if="errors.confirm_password" class="text-red-500 text-sm mt-1">{{
                                    errors.confirm_password[0] }}</p>
                            </div>
                            <button type="submit"
                                class="px-6 py-2 bg-[#c5992a] text-[#100f0d] rounded-full transition-colors duration-300 hover:bg-[#ead4a2]">
                                Change Password
                            </button>
                        </form>
                        <div class="mt-6">
                            <h3 class="text-lg font-semibold mb-2">Password Requirements:</h3>
                            <ul class="list-disc list-inside text-[#f5f3f5]">
                                <li v-for="requirement in passwordRequirements" :key="requirement">{{ requirement }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Cancel Subscription Modal -->
        <div v-if="showCancelModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-6 rounded-lg shadow-lg w-1/3">
                <h3 class="text-xl font-semibold mb-4">Confirm Cancellation</h3>
                <p class="mb-4">Are you sure you want to cancel your subscription?</p>
                <div class="flex justify-end space-x-4">
                    <button @click="closeCancelModal"
                        class="px-4 py-2 bg-gray-600 text-white rounded-full transition-colors duration-300 hover:bg-gray-700">
                        No
                    </button>
                    <button @click="cancelSubscription"
                        class="px-4 py-2 bg-red-600 text-white rounded-full transition-colors duration-300 hover:bg-red-700">
                        Yes, Cancel
                    </button>
                </div>
            </div>
        </div>

        <!-- Avatar Upload Modal -->
        <div v-if="showAvatarUploadModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-6 rounded-lg shadow-lg w-1/3">
                <h3 class="text-xl font-semibold mb-4">Upload Avatar</h3>
                <input type="file" @change="onFileChange" class="mb-4 w-full p-2 border rounded">
                <div v-if="errors.avatar" class="text-red-600 mb-4">
                    <ul>
                        <li v-for="(error, index) in errors.avatar" :key="index">{{ error }}</li>
                    </ul>
                </div>
                <div class="flex justify-end space-x-4">
                    <button @click="showAvatarUploadModal = false"
                        class="px-4 py-2 bg-gray-600 text-white rounded-full transition-colors duration-300 hover:bg-gray-700">
                        Cancel
                    </button>
                    <button @click="uploadAvatar"
                        class="px-4 py-2 bg-blue-600 text-white rounded-full transition-colors duration-300 hover:bg-blue-700">
                        Upload
                    </button>
                </div>
            </div>
        </div>

        <!-- Avatar Delete Modal -->
        <div v-if="showAvatarDeleteModal" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-6 rounded-lg shadow-lg w-1/3">
                <h3 class="text-xl font-semibold mb-4">Delete Avatar</h3>
                <p>Are you sure you want to remove your profile picture?</p>
                <div class="flex justify-end space-x-4">
                    <button @click="showAvatarDeleteModal = False"
                        class="px-4 py-2 bg-gray-600 text-white rounded-full transition-colors duration-300 hover:bg-gray-700">
                        Cancel
                    </button>
                    <button @click="destroyAvatar"
                        class="px-4 py-2 bg-blue-600 text-white rounded-full transition-colors duration-300 hover:bg-blue-700">
                        Delete
                    </button>
                </div>
            </div>
        </div>

        <FooterComponent />
    </div>
</template>



<script setup>
import NavbarComponent from '@/components/NavbarComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import Spinner from '@/components/LoadingSpinner.vue';
import api from '@/api';
import storage from '@/constants';
import { ref, onMounted, computed, provide } from 'vue';
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import defaultAvatar from '@/assets/images/default-avatar.jpg';

const notifySuccess = (message = '') => {
    const toastId = toast.success(message, {
        theme: 'colored',
        autoClose: 2000,
    })
    return toastId
}

const notifyError = (message = '') => {
    const toastId = toast.error(message, {
        theme: 'colored',
        autoClose: 2000,
    })
    return toastId
}

const user = ref(null);
const initialUser = ref(null);
const subscription = ref(null);
const showCancelModal = ref(false);
const avatar = ref(null);
const showAvatarUploadModal = ref(false);
const showAvatarDeleteModal = ref(false);


const fetchUser = async () => {
    try {
        const response = await api.get('/user');
        user.value = response.data;
        initialUser.value = JSON.parse(JSON.stringify(response.data));
        subscription.value = response.data.subscription;
    } catch (error) {
        console.error("Failed to fetch user details:", error);
    }
};

const cancelSubscription = async () => {
    try {
        const response = await api.delete(`/stripe/cancel-subscription/${subscription.value.stripe_subscription_id}`);

        if (response.ok) {
            const data = response.data;
            notifySuccess(data.message);
            getUserSubscription();
        } else {
            const errorData = response.data;
            notifyError(errorData.message);
        }
    } catch (error) {
        notifyError('Failed to cancel subscription');
    } finally {
        closeCancelModal();
    }
}

const getUserSubscription = async () => {
    try {
        const response = await api.get('/stripe/subscription');
        subscription.value = response.data;
    } catch (error) {
        console.error(error.message);
    }
};

const onFileChange = (event) => {
    avatar.value = event.target.files[0];
};

const uploadAvatar = async () => {
    try {
        const formData = new FormData();
        formData.append('avatar', avatar.value);

        await api.post('/user/avatar', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        avatar.value = null;
        errors.value = [];
        notifySuccess('Avatar Updated Successfully');
        fetchUser();
        getUserSubscription();
        showAvatarUploadModal.value = false; // Move this line here
    } catch (error) {
        notifyError('Avatar Update Failed');
        if (error.response && error.response.status === 422) {
            errors.value = error.response.data.errors;
        }
    }
};

const destroyAvatar = async () => {
    try {
        await api.delete('/user/avatar');
        notifySuccess('Avatar Removed Successfully');
        fetchUser();
    } catch (error) {
        notifyError('Failed to remove avatar');
    } finally {
        showAvatarDeleteModal.value = false;
    }
};

const isProfileChanged = computed(() => {
    const isChanged = JSON.stringify(user.value) !== JSON.stringify(initialUser.value);
    console.log('Profile Changed:', isChanged);
    return isChanged;
});

const updateProfile = async () => {
    try {
        const response = await api.put('/user/profile', user.value);
        notifySuccess(response.data.message);
        fetchUser();
        getUserSubscription();
    } catch (error) {
        notifyError('Failed to update profile');
    }
};

const subscriptionBackgroundClass = computed(() => {
    if (user.value.subscription === 'Platinum') {
        return 'bg-gradient-to-r from-gray-300 to-gray-500 text-black';
    } else if (user.value.subscription === 'Gold') {
        //return 'bg-gradient-to-r from-yellow-300 to-yellow-500 text-black';
        return 'bg-gradient-to-r from-[#DFBD69] to-[#926F34] text-black';
    } else {
        //return 'bg-[#100f0d] border-[#2b1b0d] text-white';
        //return 'bg-gradient-to-r from-blue-400  to-blue-600 text-white';
        return 'bg-[#F4F0DB] border-[#2b1b0d] text-black';
    }
});

const openCancelModal = () => {
    showCancelModal.value = true;
}

const closeCancelModal = () => {
    showCancelModal.value = false;
}

const formattedStartDate = computed(() => {
    return new Date(subscription.value.start_date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
});

const formattedEndDate = computed(() => {
    return new Date(subscription.value.end_date).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
});

const formattedMemberSince = computed(() => {
    return new Date(user.value.created_at).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
});

const activeTab = ref('profile');
const currentPassword = ref('');
const newPassword = ref('');
const confirmPassword = ref('');
const passwordRequirements = [
    'Minimum 8 characters long - the more, the better',
    'At least one uppercase character',
    'At least one number, or one symbol character',
]
const errors = ref([])

const updatePassword = async () => {
    try {
        errors.value = []
        const response = await api.put('/user/password', {
            current_password: currentPassword.value,
            new_password: newPassword.value,
            new_password_confirmation: confirmPassword.value,
        })
        currentPassword.value = null
        newPassword.value = null
        confirmPassword.value = null
        notifySuccess('Password updated successfully')
    } catch (error) {
        if (error.response && error.response.status === 422) {
            errors.value = error.response.data.errors
            notifyError('Please check the form for errors')
        } else {
            //emit('error', '...')
        }
    }
}
onMounted(() => {
    fetchUser();
    getUserSubscription();
});
</script>

<style scoped>
.custom-container {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 80px;
}
</style>
