<template>
  <div>
    <HeaderComponent />
    <div class="container-fluid pdf-container mt-4">
      <div v-if="selectedBook">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 text-center title">
            <h1>{{ selectedBook.title }}</h1>
            <p>{{ selectedBook.author }}</p>
          </div>
        </div>
        <!-- Flipbook -->
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <div class="flipbook_container">
              <!-- <Flipbook class="flipbook" :pages="pages" v-slot="flipbook">
                <div class="action-bar">
                  <button :class="{ disabled: !flipbook.canFlipLeft }" @click="flipbook.flipLeft"><i
                      class="fas fa-chevron-left"></i></button>
                  <button :class="{ disabled: !flipbook.canZoomOut }" @click="flipbook.zoomOut"><i
                      class="fas fa-minus"></i></button>
                  <span class="page-num">
                    Page {{ flipbook.page }} of {{ flipbook.numPages }}
                  </span>
                  <button :class="{ disabled: !flipbook.canZoomIn }" @click="flipbook.zoomIn"><i
                      class="fas fa-plus"></i></button>
                  <button :class="{ disabled: !flipbook.canFlipRight }" @click="flipbook.flipRight"><i
                      class="fas fa-chevron-right"></i></button>
                </div>
              </Flipbook> -->
              <Flipbook class="flipbook" :pages="pages" v-slot="flipbook" :flipDuration="500" :startPage="startPage">
                <div class="action-bar">
                  <button :class="{ disabled: !flipbook.canFlipLeft }" @click="flipbook.flipLeft">
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  <button :class="{ disabled: !flipbook.canZoomOut }" @click="flipbook.zoomOut">
                    <i class="fas fa-minus"></i>
                  </button>
                  <span class="page-num">
                    Page {{ flipbook.page }} of {{ flipbook.numPages }}
                  </span>
                  <button :class="{ disabled: !flipbook.canZoomIn }" @click="flipbook.zoomIn">
                    <i class="fas fa-plus"></i>
                  </button>
                  <button :class="{ disabled: !flipbook.canFlipRight }" @click="flipbook.flipRight">
                    <i class="fas fa-chevron-right"></i>
                  </button>
                  <input type="number" v-model="jumpPage" @keyup.enter="jumpToPage(flipbook)" min="1"
                    :max="flipbook.numPages" placeholder="Jump to page" />
                </div>
              </Flipbook>
            </div>
          </div>
        </div>
        <!-- Description -->
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 text-center book-description">
            <h2>Description</h2>
            <p>{{ selectedBook.description }}</p>
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-12 col-md-10">
            <!-- Star Rating -->
            <div class="star-rating text-center mb-3">
              <span v-for="star in 5" :key="star" class="star" @click="rate(star)">
                <i class="fa fa-star" :class="{ 'filled': star <= rating, 'empty': star > rating }"></i>
              </span>
              <div class="avg-rating">
                Avg rating: {{ avgRating.toFixed(1) }}/5 <i class="fa fa-star"></i>
              </div>
            </div>
            <!-- Comment Box -->
            <div class="comment-box">
              <textarea v-model="comment" class="form-control" rows="5"
                placeholder="Write your comment here..."></textarea>
            </div>
            <div class="d-flex justify-content-end mt-2">
              <button @click="submitComment" class="btn btn-primary comment-button">Leave a comment...</button>
            </div>
            <!-- Comments List -->
            <div class="comments-list mt-4">
              <div v-for="(comment, index) in comments" :key="index" class="comment-item">
                <div class="comment-header">
                  <strong>{{ comment.user.first_name }} {{ comment.user.last_name }}</strong>
                  <div class="comment-date">{{ formatDate(comment.created_at) }}</div>
                  <!-- <span v-if="findUserRating(comment.user_id)" class="user-rating">
                  Rating: {{ findUserRating(comment.user_id) }} <i class="fa fa-star"></i>
                </span>
                <span v-else class="user-rating">
                  No rating
                </span> -->
                </div>
                <div class="comment-body">
                  <p>{{ comment.comment }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import Flipbook from 'flipbook-vue';
import FooterComponent from "../components/FooterComponent.vue";
import HeaderComponent from "../components/NavbarComponent.vue";
import api from '@/api';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import storage from '@/constants';

const props = defineProps({
  id: Number,
});

const route = useRoute();
const bookId = ref(route.params.id || "");
const rating = ref(0);
const ratings = ref([]);
const comment = ref("");
const comments = ref([]);
const pages = ref([]);
const avgRating = ref(0);
const selectedBook = JSON.parse(localStorage.getItem('bookDetails'));
const jumpPage = ref(null)
const startPage = ref(null)

const getCommentsAndRatings = async () => {
  try {
    const response = await api.get(`/books/${bookId.value}/comments-and-ratings`);
    comments.value = response.data.comments;
    ratings.value = response.data.ratings;

    if (ratings.value.length > 0) {
      const totalRating = ratings.value.reduce((sum, rating) => sum + rating.rating, 0);
      avgRating.value = totalRating / ratings.value.length;
    } else {
      avgRating.value = 0;
    }

    const cachedUser = sessionStorage.getItem('user');
    let userId = null;
    if (cachedUser) {
      const userObject = JSON.parse(cachedUser);
      userId = userObject.id;
      const userRating = ratings.value.find(rating => rating.user_id === userId);
      if (userRating) {
        rating.value = userRating.rating;
      }
    }

  } catch (error) {
    console.error('Error fetching comments and ratings:', error);
  }
};

const jumpToPage = (flipbook) => {
  if (jumpPage.value >= 1 && jumpPage.value <= flipbook.numPages) {
    startPage.value = jumpPage.value;
  } else {
    alert('Invalid page number');
  }
}

const rate = async (star) => {
  rating.value = star;
  try {
    await api.post(`/books/${bookId.value}/rate`, { rating: star });
    getCommentsAndRatings();
  } catch (error) {
    console.error('Error submitting rating:', error);
  }
};

const submitComment = async () => {
  try {
    await api.post(`/books/${bookId.value}/comment`, { comment: comment.value });
    comment.value = '';
    getCommentsAndRatings()
  } catch (error) {
    console.error('Error submitting comment:', error);
  }
};

const findUserRating = (userId) => {
  const userRating = ratings.value.find(rating => rating.user_id === userId);
  return userRating ? userRating.rating : null;
};

const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
};

const loadPdf = async (path) => {
  try {
    //const url = `https://localhost:8080/api/storage/${path}`; //for localhost testing
    const url = `https://api.wisdomebooksclub.com/api/storage/${path}`; //for production
    const loadingTask = pdfjsLib.getDocument(url);
    const pdf = await loadingTask.promise;
    const numPages = pdf.numPages;

    for (let pageNum = 1; pageNum <= numPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 1.5 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      pages.value.push(canvas.toDataURL());
    }
  } catch (error) {
    console.error('Error loading PDF:', error);
  }
};

onMounted(async () => {
  window.scrollTo(0, 0);
  getCommentsAndRatings();
  //this is for link for the production storage
  loadPdf(`books/pdf/${bookId.value}.pdf`);
});
</script>

<style scoped>
/* Flipbook Styling */
.flipbook_container {
  width: auto;
  height: 50rem;
}

.flipbook {
  width: auto;
  height: 50rem;
}

.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-bar .btn {
  font-size: 30px;
  color: #999;
}

.action-bar .btn svg {
  bottom: 0;
}

.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}

.action-bar .btn:active {
  filter: none !important;
}

.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}

.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
  color: white;
}

.title h1 {
  font-size: 30px;
  font-weight: 400;
  color: #f1c503;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}

.title p {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.book-description {
  background-color: #110f0d;
  padding: 20px;
  border-radius: 10px;
  color: #fff;
  margin-top: 10px;
}

.book-description h2 {
  color: #f1c503;
  margin-bottom: 10px;
}

.book-description p {
  font-size: 16px;
  color: #d1d1d1;
}

.comment-box {
  margin-top: 20px;
}

.comment-box textarea {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px;
}

.comment-button {
  font-size: 16px;
  color: #000;
  padding: 10px 20px;
  background-color: #f1c503;
  border: none;
  border-radius: 25px;
}

.comment-date {
  font-size: 0.9em;
  color: gray;
}

/* Star Rating Styling */
.star-rating {
  font-size: 24px;
}

.star {
  cursor: pointer;
  color: #f1c503;
}

.star i {
  margin: 0 2px;
}

.star i.empty {
  color: #d1d1d1;
}

.star i.filled {
  color: #f1c503;
}

.comments-list {
  margin-top: 1rem;
  color: white;
}

.comment-item {
  border-bottom: 1px solid #ddd;
  /* padding: 1rem 0; */
}

.comment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
}

.user-rating {
  font-size: 0.875rem;
  color: whitesmoke;
}

.comment-body {
  margin-top: 0.5rem;
}

.avg-rating {
  margin-top: 10px;
  font-size: 16px;
  color: whitesmoke;
}

.action-bar button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  /* Adjust the size as needed */
  margin: 0 5px;
  cursor: pointer;
}

.action-bar button.disabled {
  color: gray;
  cursor: not-allowed;
}

.action-bar .page-num {
  color: white;
  font-size: 1.2rem;
  /* Adjust the size as needed */
  margin: 0 10px;
}
</style>
